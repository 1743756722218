import React from "react";
// import tw, { styled } from "twin.macro"
import "styled-components/macro";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Headlines from "@shared/components/Headlines";
import { BACKUP_LOGO_URL, MAIN_COLOR, SITE } from "../config";
import { useSiteFilter } from "../context/SiteFilterContext";

// const Grid = styled.div`
//   ${tw`grid grid-cols-1 sm:grid-cols-2 gap-8 mt-12`}
// `
// const Header = styled.div`
//   ${tw`border-l-4 border-l-red-600 pl-4 font-bold`}
// `

// const Divider = styled.div`
//   ${tw`h-[1px] bg-gray-200 my-2`}
// `

const IndexPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { seo },
    },
  } = data;
  // const { homeTeam, filterDataByTeam } = useSiteFilter();
  // let filteredData = filterDataByTeam(data, homeTeam);

  return (
    <Layout
      seoTitle={seo?.title || "Home"}
      seoDescription={seo?.description}
      isHomePage={true}
    >
      <Headlines
        data={data}
        logoUrl={BACKUP_LOGO_URL}
        color={MAIN_COLOR}
        site={SITE}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
      }
    }

    headlines: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "news-post" }, publish: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 4
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          date
          hero {
            pageImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          team
        }
        excerpt(pruneLength: 150)
      }
    }
  }
`;
